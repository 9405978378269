var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("dropdown", {
    attrs: {
      "data-cy": "boardSelector",
      values: _vm.currentBoardsDropdownItems,
      placeholder: "Select the board for application",
    },
    model: {
      value: _vm.boardId,
      callback: function ($$v) {
        _vm.boardId = $$v
      },
      expression: "boardId",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }