
import { Component, Prop, Vue, VModel } from 'vue-property-decorator';
import Dropdown from '@/components/Forms/Elements/DropdownComponent.vue';
import { PropType } from 'vue';
import { Board } from '@/models/Entities/Board';
import { DropdownOptions } from '@/models/Forms/FormOption';
import { formatDate } from '@/services/formatService';
import { sortDatesAscending } from '@/util/date';

@Component<BoardSelectorComponent>({
  components: {
    Dropdown
  }
})
export default class BoardSelectorComponent extends Vue {
  @VModel({
    required: true
  })
  boardId!: number;

  @Prop({
    type: Array as PropType<Array<Board>>,
    required: true
  })
  private boards!: Board[];

  private getBoardDropDownLabel(board: Board, isCurrentBoard = false): string {
    const boardMessage = `${board.title}: ${formatDate(board.meetingDate)}`;
    return isCurrentBoard ? boardMessage + ' (Current Board)' : boardMessage;
  }

  get currentBoardsDropdownItems(): DropdownOptions[] {
    const filteredBoards = this.boards.filter((board) => board.isActive);
    filteredBoards.sort((boardA, boardB) =>
      sortDatesAscending(boardA.startDate, boardB.startDate)
    );

    const currentBoard = filteredBoards.shift();
    const dropdownOptions = filteredBoards.map<DropdownOptions>(
      (board: Board) => {
        const label = this.getBoardDropDownLabel(board);
        const value = board.id;
        return { label, value };
      }
    );
    if (currentBoard) {
      dropdownOptions.unshift({
        label: this.getBoardDropDownLabel(currentBoard, true),
        value: currentBoard.id
      });
    }
    return [{ label: 'None', value: null }, ...dropdownOptions];
  }
}
